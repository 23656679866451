// server addresses
export const BASE_URL = 'http://api.znac.org';

// error messages
export const INTERNAL_SERVER_ERROR_MSG = 'Request error. There may be a connection problem or the server is unavailable. Wait and try again.';
export const DEFAULT_ERROR_MSG = 'Something went wrong...';
export const NOT_FOUND_ERROR_MSG = 'Nothing found';
export const USER_NOT_FOUND_ERROR_MSG = 'User is not found';
export const PHOTO_NOT_FOUND_ERROR_MSG = 'Photo is not found';
export const AUTHORIZATION_FAILED_ERROR_MSG = 'Authorisation Error';
export const UNAUTHORIZED_ERROR_MSG = 'Authorization required';
export const BAD_REQUEST_ERROR_MSG = 'The entered data was not validated on the server';
export const CONFLICT_SIGNUP_EMAIL_ERROR_MSG = 'User with this E-mail already exists';
export const CONFLICT_UPDATE_EMAIL_ERROR_MSG = 'The email you entered is already in use';
export const PHOTO_FORBIDDEN_ERROR_MSG = 'You are not allowed to delete the photo.';
export const ADD_PHOTO_ERROR_MSG = 'Failed to add photo. There may be a connection problem or the server is unavailable. Please wait and try again.';
export const DELETE_PHOTO_ERROR_MSG = 'Failed to delete photo. There may be a connection problem or the server is unavailable. Please wait and try again.';

// modal messages
export const SUCCESSFUL_SIGNUP_MSG = 'You have successfully signed up';
export const SUCCESSFUL_PROFILE_UPDATE_MSG = 'Data has been updated';

// screen width
export const LARGE_SCREEN_WIDTH = 1240;
export const MIDDLE_SCREEN_WIDTH = 1141;
export const SMALL_SCREEN_WIDTH = 601;

// photos constants
export const LARGE_SCREEN_PHOTOS_NUMBER = 12;
export const MIDDLE_SCREEN_PHOTOS_NUMBER = 9;
export const SMALL_SCREEN_PHOTOS_NUMBER = 6;
export const LARGE_SCREEN_PHOTOS_TO_ADD_NUMBER = 4;
export const MIDDLE_SCREEN_PHOTOS_TO_ADD_NUMBER = 3;
export const SMALL_SCREEN_PHOTOS_TO_ADD_NUMBER = 2;